window.addEventListener("DOMContentLoaded", () => {

  const searchButton = document.getElementById("subsection-search-btn") as HTMLElement;
  const searchFormContainer = document.getElementById("search-form") as HTMLElement;
  const searchInput = document.querySelector("#search-form input[type='search']") as HTMLInputElement | null;
  
  if (searchButton === null || searchFormContainer === null || searchInput == null) {
	  return;
  }

  function showHideSearch(event: MouseEvent): void {
    event.preventDefault();
    const targetElement = (event.target as HTMLElement).closest("a");

    if (!targetElement) {
      return;
    }

    targetElement.classList.toggle("active");
    searchFormContainer.classList.toggle("hidden-form");

    if (targetElement.classList.contains("active")) {
        searchInput.focus();
    } else {
        searchInput.value = '';
    }

  }

  searchButton.addEventListener("click", showHideSearch);

});